'use strict';

// Require vendors
require('../../node_modules/core-js/index.js');
require('../../vendor/angular/angular');
require('../../vendor/angular-bootstrap-datetimepicker/src/js/datetimepicker');
require('../../vendor/angular-sanitize/angular-sanitize');
require('../../vendor/angular-ui-router/release/angular-ui-router');
require('../../vendor/angular-resource/angular-resource');
require('../../vendor/angular-bootstrap/ui-bootstrap');
require('../../vendor/angular-bootstrap/ui-bootstrap-tpls');
require('../../vendor/angular-translate/angular-translate');
require('../../vendor/angular-translate-loader-url/angular-translate-loader-url');
require('../../vendor/angular-translate-loader-static-files/angular-translate-loader-static-files');
require('../../vendor/angular-tablesort/js/angular-tablesort');
require('../../vendor/angular-strap/dist/angular-strap');
require('../../vendor/angular-strap/dist/angular-strap.tpl');
require('../../vendor/angular-dynamic-locale/dist/tmhDynamicLocale');
require('../statics-scripts/angular-ui-select');
